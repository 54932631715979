import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { Fragment, useState } from "react";

const PrivacyPolicy = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        variant="text"
        endIcon={<OpenInNewOutlinedIcon />}
        onClick={handleClickOpen}
        sx={{ textTransform: "none" }}
      >
        Privacy Policy
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Privacy Policy"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Ford Neural Machine Translator is trained with Ford-specific
            terminology and data. All translations are done internally and none
            of this information is ever shared outside the company. The
            documents that you translate are securely stored and are deleted
            automatically within 24 hours from our storage location.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PrivacyPolicy;
