import { useState } from "react";

import { useMsal } from "@azure/msal-react";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { supportService } from "./supportService";

export interface SupportDialogProps {
  onClose: () => void;
  open: boolean;
}

const SupportDialog = ({ onClose, open }: SupportDialogProps) => {
  const [issue, setIssue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // pull user's given name from auth context
  const authContext = useMsal();
  const userFullName = authContext.accounts.at(0)?.name;
  const userGivenName = userFullName?.slice(
    userFullName.indexOf(", ") + 2,
    userFullName.indexOf(" (")
  );

  const handleClose = () => {
    setError(null);
    setIssue("");
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await supportService(issue, authContext);
      handleClose();
    } catch (error) {
      setError("*Unable to submit your issue. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          padding: "30px",
        },
      }}
    >
      <DialogTitle className="text-primary">
        Support Center
        <Typography>
          Welcome{userGivenName && ", " + userGivenName}. How can we help?
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 32,
          top: 24,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent className="flex flex-col">
        <DialogContentText>
          Please describe the issue you're experiencing in detail.
        </DialogContentText>
        <TextField
          aria-label="input"
          autoFocus
          className="mb-8"
          fullWidth
          margin="dense"
          multiline
          onChange={(event) => setIssue(event.target.value)}
          required
          rows={3}
          type="text"
        />
      </DialogContent>

      <DialogActions>
        {loading && <CircularProgress size="1.5rem" />}
        <Button
          className="self-start"
          disabled={!issue || issue.trim() === "" || loading || error !== null}
          onClick={handleSubmit}
          sx={{
            "&.Mui-disabled": {
              background: "#abcbeb",
              color: "#ffffff",
            },
          }}
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
      {error && <span className="text-error1">{error}</span>}
    </Dialog>
  );
};

export default SupportDialog;
