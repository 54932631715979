import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Props {
  error: string;
  open: boolean
  onClose: () => void
}

const ErrorDialog = ({ error, open, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="items-center flex gap-2">
        <ErrorOutlineIcon color="error" /> Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
