import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import DebouncedTextInput from "./components/DebouncedTextInput";
import FileSelector from "./components/FileSelector";
import FileTranslationOutput from "./components/FileTranslationOutput";
import LanguageSelector from "./components/LanguageSelector";
import LanguageSelectorWithDetection from "./components/LanguageSelectorWithDetection";
import translateData from "../translator/translateService";
import { Language } from "../../types/language";
import {
  TranslationRequest,
  DocumentTranslationResponse,
  TextTranslationResponse,
  TranslationResponse,
} from "../../types/translation";
import { TranslationRequestFormData } from "./types/translationForm";
import { TargetText } from "./components/TargetText";
import { useAuth } from "../auth/AuthProvider";

interface Props {
  languages: Language[];
}

const TranslationForm = ({ languages }: Props) => {
  const { getAccessToken } = useAuth();
  const [formData, setFormData] = useState<TranslationRequestFormData>({
    text: null,
    sourceLanguage: null,
    targetLanguage: null,
  });
  const [formValid, setFormValid] = useState<boolean>(false);
  const [translation, setTranslation] = useState<{
    data: TranslationResponse | null;
    loading: boolean;
    error: string | null;
  }>({
    data: null,
    loading: false,
    error: null,
  });

  // could we make the form fields all submits that only submit when the entire form is valid
  // should call translate api here (after any valid form submission)
  useEffect(() => {
    const autoSubmit = () => {
      if (formValid) {
        const translate = async (translationRequest: TranslationRequest) => {
          console.info(translationRequest.text);
          try {
            setTranslation({
              data: null,
              loading: true, // translation has begun
              error: null,
            });
            const translation = await translateData(
              translationRequest,
              getAccessToken
            );
            // translation has completed with data
            setTranslation({
              data: translation,
              loading: false,
              error: null,
            });
          } catch (error) {
            // update state if error is from service
            if (error instanceof Error && error.name !== "AbortError") {
              // translation has completed with error
              setTranslation({
                data: null,
                loading: false,
                error:
                  "Unable to translate your text at this time. Please try again later.",
              });
            }
            // Do not update state if the request is aborted
            //     This means a new request overrides the aborted one
            //     A race condition was occuring between the multiple requests
          }
        };

        // Perform the translation since the form is valid
        translate(formData as TranslationRequest);
      }
    };

    autoSubmit();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.sourceLanguage,
    formData.targetLanguage,
    formData.text,
    formValid,
  ]); // Only re-run the effect if formData, or formValid changes

  // clear translation when input is cleared
  useEffect(() => {
    if (formData.text === null || formData.text === "")
      setTranslation({ data: null, loading: false, error: null });
  }, [formData.text]);

  const handleTextChange = (text: string | File | null) => {
    const updatedFormData = {
      ...formData,
      text: text,
    };
    setFormData(updatedFormData);
    setFormValid(validateForm(updatedFormData));
  };

  const handleSourceLanguageChange = (language: Language | null) => {
    const updatedFormData = {
      ...formData,
      sourceLanguage: language,
    };
    setFormData(updatedFormData);
    setFormValid(validateForm(updatedFormData));
  };

  const handleTargetLanguageChange = (language: Language | null) => {
    const updatedFormData = {
      ...formData,
      targetLanguage: language,
    };
    setFormData(updatedFormData);
    setFormValid(validateForm(updatedFormData));
  };

  // Can only translate from/to english to/from non-english
  const disableLanguageBasedOnFormData = (
    language: Language,
    otherLanguageSelection: Language | null
  ) => {
    return !otherLanguageSelection ||
      otherLanguageSelection.name.includes("English")
      ? true
      : (language.name === "English" || language.name.includes("Auto")) ?? // Auto detect does not need to be enabled if english is the only allowed source language
          false;
  };

  const sourceLanguages = languages.map((language) => {
    return {
      ...language,
      enabled: disableLanguageBasedOnFormData(
        language,
        formData.targetLanguage
      ),
    };
  });

  const targetLanguages = languages.map((language) => {
    return {
      ...language,
      enabled: disableLanguageBasedOnFormData(
        language,
        formData.sourceLanguage
      ),
    };
  });

  // currently only needed for text (string) form
  const swapSourceAndTarget = () => {
    const updatedFormData = {
      text:
        translation?.data && "translatedText" in translation?.data
          ? translation?.data?.translatedText
          : null,
      sourceLanguage: formData.targetLanguage,
      targetLanguage: getTargetLanguage(formData.sourceLanguage),
    };
    setFormData(updatedFormData);
    setFormValid(validateForm(updatedFormData));
  };

  const getTargetLanguage = (
    sourceLanguage: Language | null
  ): Language | null => {
    if (sourceLanguage?.name.includes("Auto Detect - ")) {
      return {
        name: sourceLanguage.name.slice(14),
        code: sourceLanguage.code,
      };
    } else {
      return sourceLanguage;
    }
  };

  const validateForm = (data: TranslationRequestFormData) => {
    const isSupportedDocument = (mimeType: string): boolean => {
      // these could be moved to a configuration to avoid code changes
      const supportedMimeTypes: string[] = [
        "application/msword", // .doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-powerpoint", // .ppt
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
        "application/pdf", // .pdf
      ];

      return supportedMimeTypes.includes(mimeType);
    };

    const text = data.text;
    const hasValidLanguages =
      data.sourceLanguage !== null &&
      data.targetLanguage !== null &&
      data.sourceLanguage !== data.targetLanguage;
    const hasContentToTranslate =
      (typeof text === "string" && text.trim() !== "") || // is not an empty string
      (text instanceof File && text.size >= 0); // is not an empty File
    const hasValidContent =
      typeof text === "string" || // is any string
      (text instanceof File && isSupportedDocument(text.type)); // is supported file type

    return hasValidLanguages && hasContentToTranslate && hasValidContent;
  };

  // File Form
  if (formData.text instanceof File) {
    return (
      <div className="flex flex-col sm:flex-row gap-4 justify-center w-full">
        <div className="w-full md:w-2/5">
          <LanguageSelector
            label="Translate from"
            languages={sourceLanguages}
            selectedLanguage={formData.sourceLanguage}
            onLanguageSelection={handleSourceLanguageChange}
          />
          <FileSelector
            selectedFile={formData.text}
            onFileSelect={handleTextChange}
          />
        </div>

        {/* switch languages - DISABLED*/}
        <div className="my-2 mx-auto sm:mx-0">
          <IconButton
            className="rotate-90 sm:rotate-0 self-center"
            onClick={() => {}}
            disabled={true}
          >
            <SyncAltIcon />
          </IconButton>
        </div>

        <div className="w-full md:w-2/5">
          <LanguageSelector
            label="Translate to"
            languages={targetLanguages}
            selectedLanguage={formData.targetLanguage}
            onLanguageSelection={handleTargetLanguageChange}
          />
          {/* Translation Output */}
          <FileTranslationOutput
            documentTranslation={{
              loading: translation.loading,
              error: translation.error,
              data: (translation.data as DocumentTranslationResponse) ?? null,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col sm:flex-row gap-4 justify-center w-full">
      <div className="w-full md:w-2/5">
        <LanguageSelectorWithDetection
          languages={sourceLanguages}
          selectedLanguage={formData.sourceLanguage}
          onLanguageSelection={handleSourceLanguageChange}
          inputText={formData.text ?? ""}
        />
        <DebouncedTextInput
          value={formData.text ?? ""}
          onDelayedInput={handleTextChange}
          placeholder="Enter text to translate"
        />
        <FileSelector disabled={formData.text !== null && formData.text.length > 0} selectedFile={null} onFileSelect={handleTextChange} />
      </div>

      {/* switch languages */}
      <div className="my-2 mx-auto sm:mx-0">
        <IconButton
          className="rotate-90 sm:rotate-0 sm:self-center"
          onClick={swapSourceAndTarget}
          disabled={translation.loading}
        >
          <SyncAltIcon />
        </IconButton>
      </div>

      <div className="w-full md:w-2/5">
        <LanguageSelector
          label="Translate to"
          languages={targetLanguages}
          selectedLanguage={formData.targetLanguage}
          onLanguageSelection={handleTargetLanguageChange}
        />
        {/* Translation Output */}
        <TargetText
          translation={{
            loading: translation.loading,
            error: translation.error,
            data: (translation.data as TextTranslationResponse) ?? null,
          }}
        />
      </div>
    </div>
  );
};

export default TranslationForm;
