import { ChangeEvent, useRef } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FileIcon } from "../../../components/common/FileIcon";
import ClearIcon from "@mui/icons-material/Clear";

interface FileSelectorProps {
  selectedFile: File | null;
  onFileSelect?: (file: File | null) => void;
  disabled?: boolean;
}

const FileSelector = ({ selectedFile, onFileSelect, disabled }: FileSelectorProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (onFileSelect) {
        onFileSelect(file);
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleClearFile = () => {
    if (onFileSelect) {
      onFileSelect(null);
    }
    // Reset the value of the file input to ensure it can detect the same file if reselected
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept=".docx,.pdf,.pptx,.xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the default file input
        disabled={disabled}
      />
      {selectedFile && (
        <Card
          className="relative"
          sx={{
            height: "320px",
            position: "relative",
          }}
        >
          <CardContent
            sx={{
              maxWidth: "100%",
              height: "320px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FileIcon fileType={selectedFile.type} fontSize="large" />
            <Tooltip title={selectedFile.name}>
              <Typography
                noWrap
                variant="body1"
                style={{ marginTop: "0.5rem" }}
              >
                {selectedFile.name}
              </Typography>
            </Tooltip>
          </CardContent>

          {/* clear button */}
          <div className="absolute top-1 right-1 z-50">
            <IconButton onClick={handleClearFile} aria-label="clear">
              <ClearIcon />
            </IconButton>
          </div>
        </Card>
      )}

      <Button
        className="text-primary w-full"
        variant="outlined"
        onClick={triggerFileInput}
        disabled={disabled}
      >
        <CloudUploadOutlinedIcon fontSize="large" className="mr-3" />
        <div className="flex flex-col">
          <Typography variant="button" fontSize="medium">
            {selectedFile ? "Change File" : "Select File"}
          </Typography>
          <Typography variant="caption" fontSize="small" textTransform="none">
            Supported file types: .docx, .pdf, .pptx, .xlsx
          </Typography>
        </div>
      </Button>
    </div>
  );
};

export default FileSelector;
