import { IMsalContext } from "@azure/msal-react";
import { acquireAccessToken } from "../auth/AuthUtilities";

export const supportService = async (
  issue: string,
  authContext: IMsalContext
): Promise<string> => {
  // Acquire authorization token for user
  const accessToken = await acquireAccessToken(authContext, [
    "api://f278551a-c690-47b9-8318-f1378b92a357/user_impersonation",
  ]);

  const response = await fetch(process.env.REACT_APP_ISSUE_SERVICE_URL ?? "", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ issue: issue }),
  });

  if (!response.ok) {
    throw new Error(`HTTP Error: ${response.statusText}`);
  }

  return await response.json();
};
