import FordCopyright from "../common/FordCopyright";
import Links from "../common/Links";

const Footer = () => (
  <footer className="w-full bg-white flex justify-between items-center p-4 border-t-[1px]">
    <FordCopyright />
    <Links />
  </footer>
);

export default Footer;
