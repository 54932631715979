import { useEffect, useState } from "react";
import LanguageSelector from "./LanguageSelector";
import { Language } from "../../../types/language";
import useLanguageDetection from "../../translator/useLanguageDetection";

interface Props {
  languages: Language[];
  selectedLanguage: Language | null;
  onLanguageSelection: (selectedLanguage: Language | null) => void;

  inputText: string;
}

const LanguageSelectorWithDetection = ({
  selectedLanguage,
  languages,
  onLanguageSelection,
  inputText,
}: Props) => {

  const [languagesWithAutoDetect, setLanguagesWithAutoDetect] = useState<Language[]>([])

  useEffect(() => {
    if (languages.length > 0 && languages.findIndex(language => language.name.includes("Auto Detect")) === -1) {
      const inputLanguages = [...languages]
      inputLanguages?.splice(0, 0, {
        name: "Auto Detect",
        code: "",
        enabled: languages[0].name !== 'English' ? languages[0].enabled : languages[1].enabled,
      });

      setLanguagesWithAutoDetect(inputLanguages)
    }

  }, [languages])

  //data returns {name: 'LANGUAGE DETECTION ERROR', code: ''} when unable to translate text input; no error
  const { data: detectedLanguage, error } = useLanguageDetection(
    inputText,
    selectedLanguage?.name.includes("Auto Detect") ?? false
  );

  useEffect(() => {
    if (detectedLanguage) {
      const modifiedLanguages = [...languagesWithAutoDetect]
      modifiedLanguages[0] = {
        name: 'Auto Detect - ' + detectedLanguage.name,
        code: detectedLanguage.code,
        enabled: true
      } as Language;

      setLanguagesWithAutoDetect(modifiedLanguages);
      onLanguageSelection(modifiedLanguages[0]);
    }
  }, [detectedLanguage]);

  return (
    <LanguageSelector
      label="Translate from"
      languages={languagesWithAutoDetect ?? []}
      selectedLanguage={selectedLanguage}
      onLanguageSelection={onLanguageSelection}
    />
  );
};

export default LanguageSelectorWithDetection;
