import { Configuration, PublicClientApplication } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
const authConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        redirectUri: process.env.REACT_APP_URL,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const mlTranslateRequest = {
    scopes: ["api://f278551a-c690-47b9-8318-f1378b92a357/user_impersonation"],
};

const authClient = await PublicClientApplication.createPublicClientApplication(authConfig);

export default authClient;
