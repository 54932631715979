import { DocumentTranslationRequest, DocumentTranslationResponse } from "../../types/translation";

let controller: AbortController | undefined = undefined;

export const translateDocument = async (
    translationRequest: DocumentTranslationRequest,
    getAccessToken: (scopes: string[]) => Promise<string>,
    scopes: string[]
): Promise<DocumentTranslationResponse> => {

    if (controller) controller.abort();
    controller = new AbortController();

    // Acquire authorization token for user
    const accessToken = await getAccessToken(scopes);

    // Build request data
    const formData = new FormData();
    formData.append('file', translationRequest.text)

    let translationResponse: Response;

    // Note: logic handling how to translate pdfs belongs in the document translation api.
    //       Document translation api is exposing internal logic and we are forced to handle it
    if (isDocumentPDF(translationRequest.text)) {
        // Translate PDF
        translationResponse = await translatePdf(formData, translationRequest, accessToken, controller);
    }
    else {
        // Translate document
        const queryParams = `?langs=${translationRequest.sourceLanguage.code + translationRequest.targetLanguage.code}`//+&asynchronous=${}&is_native=${}`
        translationResponse = await fetch(
            process.env.REACT_APP_DOCUMENT_TRANSLATION_SERVICE_URL + queryParams ?? "",
            {
                method: "POST",
                signal: controller.signal,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            }
        );
    }

    if (!translationResponse.ok) {
        throw new Error(`HTTP Error: ${translationResponse.statusText}`);
    }

    controller = undefined
    const response = translationResponse.json();
    return response;
};

const isDocumentPDF = (file: File) => {
    return file.type === 'application/pdf';
}

const translatePdf = async (formData: FormData, translationRequest: DocumentTranslationRequest, accessToken: string, controller: AbortController) => {
    let queryParams = '';
    let response: Response;

    // try to translate the pdf as a native pdf
    queryParams = `?langs=${translationRequest.sourceLanguage.code + translationRequest.targetLanguage.code}&is_native=${true}`
    response = await fetch(
        process.env.REACT_APP_DOCUMENT_TRANSLATION_SERVICE_URL + queryParams ?? "",
        {
            method: "POST",
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        }
    );

    if (!response.ok) {
        // if it fails, try to translate as non-native (image) pdf
        queryParams = `?langs=${translationRequest.sourceLanguage.code + translationRequest.targetLanguage.code}&is_native=${false}`
        response = await fetch(
            process.env.REACT_APP_DOCUMENT_TRANSLATION_SERVICE_URL + queryParams ?? "",
            {
                method: "POST",
                signal: controller.signal,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            }
        );
    }

    return response;
}

export default translateDocument;