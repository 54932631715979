import { AuthProvider } from "./features/auth/AuthProvider";
import Toolbar from "./components/layout/Toolbar";
import Translator from "./features/translator/Translator";
import Footer from "./components/layout/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from './muiTheme';

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
      <div className="flex flex-col min-h-screen justify-between gap-4">
        <Toolbar />
        <div className="self-center w-5/6">
          <Translator />
        </div>
        <Footer />
      </div>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
