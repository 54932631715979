import acquireAccessToken from "../auth/AccessToken";
import { TextTranslationRequest, TextTranslationResponse } from "../../types/translation";

let controller: AbortController | undefined = undefined;

const translateText = async (
    translationRequest: TextTranslationRequest
): Promise<TextTranslationResponse> => {
    if (controller) controller.abort();
    controller = new AbortController();

    // Acquire authorization token for user
    const accessToken = await acquireAccessToken();

    // Translate text
    const translationResponse = await fetch(
        process.env.REACT_APP_TRANSLATION_SERVICE_URL ?? "",
        {
            method: "POST",
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                langs: (translationRequest.sourceLanguage.code + translationRequest.targetLanguage.code),
                text: translationRequest.text
            }),
        }
    );

    if (!translationResponse.ok) {
        throw new Error(`HTTP Error: ${translationResponse.statusText}`);
    }

    controller = undefined
    const translatedText = await translationResponse.text();
    return { translatedText: translatedText };
};

export default translateText;