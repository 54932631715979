import { useEffect, useState } from "react";
import acquireAccessToken from "../auth/AccessToken";
import { Language } from "../../types/language";

const useLanguages = () => {
    const [data, setData] = useState<Language[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getLanguages = async () => {
            try {
                setLoading(true);
                const languages = await getLanguagesApi();
                const languagesEnabledAndSorted = languages
                    .map((language) => {
                        return { name: language.name, code: language.code, enabled: true } as Language
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
                setData(languagesEnabledAndSorted);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        };

        getLanguages();
    }, []);

    return { data, loading, error };
}

const getLanguagesApi = async (): Promise<Language[]> => {
    // Acquire authorization token for user
    const accessToken = await acquireAccessToken();

    // Get languages
    const response = await fetch(
        process.env.REACT_APP_TRANSLATION_LANGUAGES_URL ?? "",
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.statusText}`);
    }

    return await response.json();
}

export default useLanguages;