import { SvgIconProps } from '@mui/material/SvgIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import { ExcelIcon } from "../logos/ExcelIcon";
import { PdfIcon } from "../logos/PdfIcon";
import { PowerpointIcon } from "../logos/PowerpointIcon";
import { WordIcon } from "../logos/WordIcon";

interface Props extends SvgIconProps {
    fileType: string
}

export const FileIcon = ({ fileType, fontSize }: Props) => {
    switch (fileType) {

        // Microsoft Excel Document
        case '.xls':
            return <ExcelIcon fontSize={fontSize} />
        case '.xlsx':
            return <ExcelIcon fontSize={fontSize} />
        case 'application/vnd.ms-excel': // .xls
            return <ExcelIcon fontSize={fontSize} />
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // .xlsx
            return <ExcelIcon fontSize={fontSize} />

        // PDF Document
        case '.pdf':
            return <PdfIcon fontSize={fontSize} />
        case 'application/pdf':
            return <PdfIcon fontSize={fontSize} />

        // Microsoft Powerpoint Document
        case '.ppt':
            return <PowerpointIcon fontSize={fontSize} />
        case '.pptx':
            return <PowerpointIcon fontSize={fontSize} />
        case 'application/vnd.ms-powerpoint': // .ppt
            return <PowerpointIcon fontSize={fontSize} />
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // .pptx
            return <PowerpointIcon fontSize={fontSize} />

        // Microsoft Word Document
        case '.doc':
            return <WordIcon fontSize={fontSize} />
        case '.docx':
            return <WordIcon fontSize={fontSize} />
        case 'application/msword': // .doc
            return <WordIcon fontSize={fontSize} />
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
            return <WordIcon fontSize={fontSize} />

        // Unknown Document Type
        default:
            return <DescriptionIcon fontSize={fontSize} />
    }
}