import { useEffect, useState } from "react";
import authClient from "../auth/AuthConfig";
import { Avatar, Tooltip, Typography } from "@mui/material";
import { User } from "../../types/user";

export const UserProfile = () => {
  const [user, setUser] = useState<User>({
    givenName: "",
    surname: "",
    photo: "",
  });

  useEffect(() => {
    authClient
      .acquireTokenSilent({
        scopes: ["User.Read"],
        account: authClient.getAllAccounts()[0],
      })
      .then((response) => {
        fetch(process.env.REACT_APP_USER_URL ?? "", {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setUser({
              givenName: data.givenName,
              surname: data.surname,
              photo: data.photo ? data.photo.medium : "",
            });
          });
      });
  }, []);

  const getInitials = (user: User) => {
    return user.givenName[0] + user.surname[0];
  };

  const tooltipContents = () => {
    return (
      <Typography fontSize={15}>{user.surname && user.givenName ? user.surname + ", " + user.givenName : "User"}</Typography>
    )
  }

  return (
    <Tooltip title={tooltipContents()}>
      <Avatar
        style={{ padding: 5, width: 55, height: 45, borderRadius: 80, backgroundColor: "#2196f3"}}
      >
        {user.photo ? (
          <img
            className="w-full h-full rounded-full"
            src={user.photo}
            alt="User Profile"
          />
        ) : (
          getInitials(user)
        )}
      </Avatar>
    </Tooltip>
  );
};
