import PrivacyPolicy from "../../features/privacyPolicy/PrivacyPolicy";
import Support from "../../features/support/Support";

const Links = () => {
  return (
    <div className="flex flex-col items-start justify-around">
      <PrivacyPolicy />
      <Support />
    </div>
  );
};

export default Links;
