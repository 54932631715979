import { TextArea } from "../../../components/common/TextArea";
import { TextTranslationResponse } from "../../../types/translation";
import { LoadingIndicator } from "./LoadingIndicator";


interface Props {
    translation: {
        loading: boolean;
        error: string | null;
        data: TextTranslationResponse | null;
    }
}

export const TargetText = ({ translation }: Props) => {
    const { loading, error, data } = translation;

    const textToDisplay = (): string => {
        return error ?? data?.translatedText ?? '';
    }

    return (
        <div className="relative">
            <TextArea
                value={textToDisplay()}
                placeholder={loading ? '' : "Translation"}
                disabled={true}
            />
            {loading && (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-20">
                    <LoadingIndicator />
                </div>
            )}
        </div>
    )
}