import { DocumentTranslationRequest, TextTranslationRequest, TranslationRequest, TranslationResponse } from "../../types/translation";
import translateDocument from "./translateDocumentService";
import translateText from "./translateTextService";
import { mlTranslateRequest } from "../auth/AuthConfig";

const translateData = async (
    translationRequest: TranslationRequest,
    getAccessToken: (scopes: string[]) => Promise<string>
): Promise<TranslationResponse> => {

    return (translationRequest.text instanceof File) ?
        await translateDocument(
            translationRequest as DocumentTranslationRequest,
            getAccessToken,
            mlTranslateRequest.scopes,
        )
        : await translateText(translationRequest as TextTranslationRequest)
}

export default translateData;