import FordLogo from "../logos/FordLogo";

const FordCopyright = () => {
  return (
    <div className="flex flex-col gap-1 text-primary">
      <FordLogo />
      <div>A product of Ford Motor Company</div>
    </div>
  );
};

export default FordCopyright;
