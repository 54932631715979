import { IMsalContext } from "@azure/msal-react";

export const acquireAccessToken = async (
  authContext: IMsalContext,
  scopes: string[]
) => {
  try {
    const { instance, accounts } = authContext;
    const request = {
      scopes: scopes,
      account: accounts[0],
    };
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    throw new Error(`Error acquiring token ${error}`);
  }
};
