import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DocumentTranslationResponse } from "../../../types/translation";
import { LoadingIndicator } from "./LoadingIndicator";
import { FileIcon } from "../../../components/common/FileIcon";


interface Props {
  documentTranslation: {
    loading: boolean;
    error: string | null;
    data: DocumentTranslationResponse | null;
  };
}

const FileTranslationOutput = ({ documentTranslation }: Props) => {
  const { data, loading, error } = documentTranslation;
  const fileUrl = data?.signed_url;
  const fileType = data?.file_name.slice(data?.file_name.lastIndexOf('.'));

  const handleDownloadClick = () => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <Card sx={{
      height: '320px', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CardContent sx={{
        maxWidth: '100%',
        height: '320px',
        display: 'flex',
        alignItems: 'center'
      }}>
        {loading && <LoadingIndicator />}
        {error &&
          <Typography>
            {error}
          </Typography>
        }
        {data &&
          <div className="flex flex-col flex-grow items-center justify-center w-full h-full"> 
            <FileIcon fileType={fileType ?? ''} fontSize="large" />
            <div className="overflow-y-auto max-h-full w-full text-center">
              <Typography className="w-3/4 inline-block" style={{ wordWrap: "break-word" }}>
              {data.file_name}
              </Typography>
            </div>
            <Tooltip title={data.signed_url}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
                onClick={handleDownloadClick}
                style={{ marginTop: '10px', textTransform: 'none' }}
                aria-label="Download translation"
              >
                Download translation
              </Button>
            </Tooltip>
          </div>
        }
      </CardContent>
    </Card >
  )
}

export default FileTranslationOutput;
