import { useState, useEffect } from "react";
import LoadingPage from "./LoadingPage";
import ErrorDialog from "../../components/common/ErrorDialog";
import TranslationForm from "../translationForm/TranslationForm";
import useLanguages from "./useLanguages";


const Translator = () => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  // languages understood by the translator
  const {
    data: languages,
    loading: loadingLanguages,
    error: errorLanguages,
  } = useLanguages();

  // error state (for translator): translator is unavailable
  useEffect(() => {
    if (errorLanguages) setOpenErrorDialog(true);
  }, [errorLanguages]);

  return (
    <div className="flex flex-col gap-4 items-center md:items-baseline justify-center md:flex-row w-full">
      {/* app */}
      {loadingLanguages ?
        <LoadingPage />
        :
        <TranslationForm
          languages={languages ?? []}
        />}

      {/* translation feature is down/unavailable */}
      {errorLanguages ? (
        <ErrorDialog
          error={'Sorry, the translator is currently down. Please try again later.'}
          open={openErrorDialog}
          onClose={() => {
            setOpenErrorDialog(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default Translator;
