import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Language } from "../../../types/language";

interface Props {
  label: string;
  languages: Language[];
  selectedLanguage: Language | null;
  onLanguageSelection: (language: Language | null) => void;
}

const LanguageSelector = ({
  label,
  languages,
  selectedLanguage,
  onLanguageSelection,
}:
  Props) => {
  const onLanguageSelectionChange = (event: any, value: Language | null) => {
    onLanguageSelection(value);
  };

  return (
    <Autocomplete
      disablePortal
      options={languages.sort((a, b) => (a.enabled ? -1 : 1) - (b.enabled ? -1 : 1))} // sort options by enabled status; true is first
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => option.enabled !== true}
      value={selectedLanguage ?? null}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onChange={onLanguageSelectionChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default LanguageSelector;
