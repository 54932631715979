interface Props {
    value: string;
    placeholder: string;
    disabled: boolean;
}

export const TextArea = ({ value, placeholder, disabled = false }: Props) => {
    return (
        <textarea
            className="p-4 border bg-gray1 rounded-md w-full resize-none h-80"
            rows={8}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
        />
    )
}