import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

const LoadingPage = () => {
  const showLoadingState = () => {
    return (
        <div className="w-full">
          <Autocomplete
            disablePortal
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label="Loading..."></TextField>
            )}
          />
          <textarea
            className="p-4 border rounded-md w-full resize-none"
            rows={8}
            placeholder="..."
          />
        </div>
    );
  };

  return (
    <>
      {showLoadingState()}

      <IconButton className="rotate-90 md:rotate-0 md:self-center">
        <SyncAltIcon />
      </IconButton>

      {showLoadingState()}
    </>
  );
};

export default LoadingPage;
