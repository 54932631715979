import { UserProfile } from "../../features/user/UserProfile";
import NLPLogo from '../logos/NLPLogo.png';


const Toolbar = () => {
  return (
    <div className="flex justify-between items-center min-h-[64px] h-fit text-white p-4 bg-primary">
      <div className="w-16">
        <img src={NLPLogo} alt="NMT" />
      </div>
      <div className="flex flex-col text-center w-3/4 gap-3">
        <h1 className="text-2xl text-white">
          FORD NEURAL MACHINE TRANSLATOR
        </h1>
        <p className="self-center tracking-wider text-white lg:w-3/4 overflow-hidden">
          The Ford Neural Machine Translator is an advanced AI-powered language
          translator that specializes in the automotive context and is
          proficient in more than 25 languages.
        </p>
      </div>
      <div className="flex items-center">
        <UserProfile />
      </div>
    </div>
  );
};

export default Toolbar;
