import { createContext, useContext, ReactNode } from "react";
import {
  Configuration,
  InteractionType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: process.env.REACT_APP_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

type AuthContextType = {
  getAccessToken: (scopes: string[]) => Promise<string>;
};
const AuthContext = createContext<AuthContextType | null>(null);

interface Props {
  children: ReactNode;
}
export const AuthProvider = ({ children }: Props) => {
  const getAccessToken = async (scopes: string[]) => {
    const account = msalInstance.getAllAccounts()[0];

    const request = {
      scopes: scopes,
      account: account,
    };

    try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      // Fallback to interactive method if silent token acquisition fails
      if (error instanceof InteractionRequiredAuthError) {
        const response = await msalInstance.acquireTokenPopup(request);
        return response.accessToken;
      } else {
        throw error;
      }
    }
  };

  // Provide the MSAL instance and getAccessToken function via context
  return (
    <MsalProvider instance={msalInstance}>
      <AuthContext.Provider value={{ getAccessToken }}>
        {/* perhaps we can use the msal instance instead of creating our own context */}
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          loadingComponent={SignInLoading}
          errorComponent={SignInError}
        >
          {children}
        </MsalAuthenticationTemplate>
      </AuthContext.Provider>
    </MsalProvider>
  );
};

const SignInLoading = () => {
  return <p>Signing in...</p>;
};

const SignInError = () => {
  return <p>Unable to sign-in in at this time. Please try again later.</p>;
};

// Hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
