import CircularProgress from "@mui/material/CircularProgress";


export const LoadingIndicator = () => {
    return (
        <div className="flex flex-col items-center text-center">
            <CircularProgress></CircularProgress>
            <div className="text-primary">
                Please wait for the translation to complete. This may take a few seconds.
            </div>
        </div>
    )
}