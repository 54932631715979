import authClient from "./AuthConfig";

const acquireAccessToken = async () => {
    const activeAccount = authClient.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = authClient.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    }
    const request = {
        scopes: ["api://f278551a-c690-47b9-8318-f1378b92a357/user_impersonation"],
        account: activeAccount || accounts[0]
    };

    const authResult = await authClient.acquireTokenSilent(request);

    return authResult.accessToken
};

export default acquireAccessToken;