import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";


interface DebouncedTextInputProps {
  value: string;
  onDelayedInput: (value: string) => void; // Callback to send the text after delay
  delay?: number; // Optional delay in milliseconds
  placeholder?: string; // Optional placeholder text
}

const DebouncedTextInput = ({
  value,
  onDelayedInput,
  delay = 500, // Default delay of 500ms
  placeholder = '',
}: DebouncedTextInputProps) => {
  const [inputValue, setInputValue] = useState('');

  // Use useCallback to memoize the debounced function so it doesn't get recreated on every render
  const debouncedInput = useCallback(
    debounce((value: string) => {
      onDelayedInput(value);
    }, delay),
    [onDelayedInput, delay]
  );

  useEffect(() => { setInputValue(value) }, [value]);

  useEffect(() => {
    // Invoke the debounced function whenever the inputValue changes
    debouncedInput(inputValue);

    // Cleanup the debounced function on component unmount
    return () => debouncedInput.cancel();
  }, [inputValue, debouncedInput]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className='relative'>
      <textarea
        className="p-4 border rounded-md w-full resize-none h-80"
        rows={8}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {/* clear button */}
      <div className="absolute top-1 right-1 z-50">
        <IconButton onClick={() => setInputValue("")} aria-label="clear" disabled={!inputValue}>
          <ClearIcon />
        </IconButton>
      </div>
    </div>

  )
};

// Utility function to debounce another function
function debounce<T extends (...args: any[]) => any>(func: T, waitFor: number) {
  let timeout: NodeJS.Timeout;

  const debounced = (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  debounced.cancel = () => clearTimeout(timeout);

  return debounced;
}

export default DebouncedTextInput;
