import { useEffect, useState } from "react";
import acquireAccessToken from "../auth/AccessToken";
import { Language } from "../../types/language";

const useLanguageDetection = (text: string, useLanguageDetection: boolean) => {
    const [data, setData] = useState<Language | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const detectLanguage = async () => {
            try {
                setLoading(true);
                // throw new Error('Lang detection failed');
                const detectedLanguage = await languageDetectionApi(text);
                setData(detectedLanguage);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        };

        if (useLanguageDetection && text.length > 0) {
            detectLanguage();
        }
    }, [text, text.length, useLanguageDetection]);

    return { data, loading, error };
}

const languageDetectionApi = async (text: string): Promise<Language> => {
    // Acquire authorization token for user
    const accessToken = await acquireAccessToken();

    // Detect language
    const response = await fetch(
        process.env.REACT_APP_LANGUAGE_DETECTION_SERVICE_URL ?? "",
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text: [text] }),
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.statusText}`);
    }

    return (await response.json())[0];
}


export default useLanguageDetection;