import { useState } from "react";

import Button from "@mui/material/Button";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import SupportDialog from "./SupportDialog";


const Support = () => {  
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button
        variant="text"
        endIcon={<OpenInNewOutlinedIcon />}
        onClick={handleClickOpen}
        sx={{ textTransform: "none" }}
      >
        Support
      </Button>
      <SupportDialog onClose={handleClose} open={openDialog} />
    </>
  );
};

export default Support;
